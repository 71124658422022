<template>
	<div>
		<headerTop></headerTop>
		<headerMin></headerMin>
		<navlist :state="3"></navlist>
		<div class="content">
			<div class="crumbs">
				<span @click="$router.push('/')">首页</span>>健康知识
			</div>
			<div class="tabs">
				<ul>
					<li :class="{active:state==index}" v-for="(i,index) in tablist" @click="onclicktabs(i.id,index)" :key="index">{{i.category_name}}<span></span></li>
				</ul>
			</div>
			<div class="textlist">
				<div class="textbox" v-for="(i,index) in textlist" :key="index" @click="gotextinfor(i.id)">
					<img :src="i.pic_url" alt="">
					<div class="textinfor">
						<p class="textTitle">{{i.title}}</p>
						<p class="textContent">{{i.content}}</p>
						<p class="textBottom"><span>阅读量: {{i.article_count}}</span><span>发布时间: {{i.add_time|gettime}}</span></p>
					</div>
				</div>
			</div>
		</div>
		<publicBottom></publicBottom>
	</div>
</template>

<script>
	import navlist from "@/components/home_repect/navlist.vue"
	import headerTop from "@/components/public/public_headerTop.vue";
	import headerMin from "@/components/index/index_header_min";
	import publicBottom from "@/components/public/public_bottom";
	export default {
		components: {
			navlist,
			headerTop,
			headerMin,
			publicBottom,
		},
		name: "healthy",
		data() {
			return {
				activeName: 'second',
				tablist: [],
				state: 0,
				textlist: [],
			}
		},
		created() {
			this.getTabs();
		},
		filters: {
			gettime(value) {
				let Y = "";
				let M = "";
				let D = "";
				let date = new Date(value * 1000);
				Y = date.getFullYear();
				M = (date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
				D = date.getDay() < 10 ? "0" + date.getDay() : date.getDay();
				return Y + "-" + M + "-" + D;
			}
		},
		methods: {
			handleClick(tab, event) {
				console.log(tab);
			},
			// 获取tabs选项
			getTabs() {
				this.$axios.post("/api/Article/getCategoryList", {
					is_yaodian: 1
				}).then(res => {
					console.log(res.data);
					if (res.data.code == 1) {
						this.tablist = res.data.data;
						this.$axios.post("/api/Article/getList", {
							category_id: this.tablist[0].id
						}).then(res => {
							if (res.data.code == 1) {
								this.textlist = res.data.data.data;
							}
						})
					}

				});
			},
			// 获取类别下的文章
			onclicktabs(id, index) {
				this.state = index;
				this.$axios.post("/api/Article/getList", {
					category_id: id
				}).then(res => {
					if (res.data.code == 1) {
						this.textlist = res.data.data.data;
					}

				})
			},
			gotextinfor(id){
				this.$router.push({name:'文章详情',query:{id:id}});
			}
			
		}
	}
</script>

<style lang="less" scoped>
	.content {
		width: 1200px;
		margin: 0 auto;
		margin-bottom: 50px;
		.crumbs {
			font-size: 14px;
			margin-top: 20px;
			margin-bottom: 10px;
		}

		.tabs {
			ul {
				border-bottom: 2px solid #ccc;
				height: 40px;

				li {
					float: left;
					padding: 10px 20px;
					position: relative;
					cursor: pointer;

					span {
						position: absolute;
						right: 0;
						top: 14px;
						display: inline-block;
						height: 15px;
						width: 2px;
						background-color: #ccc;
					}
				}

				.active {
					color: #18eab7;
					border-bottom: 2px solid #18eab7;
				}

			}

		}

		.textbox {
			margin-top: 20px;
			border: 2px solid #CCCCCC;
			display: flex;
			padding: 10px;

			img {
				width: 120px;
				height: 120px;
			}

			.textinfor {
				margin-left: 20px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				.textTitle {
					color: #18eab7;
					font-size: 24px;
				}

				.textContent {
					font-size: 14px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					word-break: break-all;
				}

				.textBottom {
					font-size: 14px;
					color: #909090;

					span {
						display: inline-block;
						margin-right: 20px;
					}
				}
			}
		}
	}
</style>
